/* src/components/SplashScreen.css */
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('/public/forest-space-background-fade.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1;
    transition: opacity 1s ease-out;
    background-position: center center;
  }
  
  .splash-screen.fade-out {
    opacity: 0;
    /* visibility: hidden; */
  }
  
  .splash-logo img {
    border-radius: 20px;
    border: 1px solid #ffffff;
    width: 150px; /* Adjust size as needed */
    height: auto;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.75);
  }