body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  background-image: url('/public/forest-space-background.png');
  background-size: cover;
  background-position: center center;
}

html,
body {
  background-color: black;
  height: 100%;
}

.background {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Adjusted transparency for the conversation div */
}

#root {
  height: 100%;
}

.background {
  opacity: 0;
}

.background.show {
  opacity: 1;
}

.App {
  display: flex;
  flex-direction: column;
  color: #e0e0e0;
  width: 100%;
  height: 100%;
}

.Playground {
  padding: 65px 15px 30px 15px;
  display: flex;
  flex-direction: column;
  color: #e0e0e0;
  position: fixed;
  width: calc(100% - 30px);
}

.playground-h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .App.open, .Playground.open {
    width: calc(100% - 540px);
    margin-left: 210px;
  }

  .navbar.open {
    width: calc(100% - 300px);
    /* Adjusted width to account for the slide-out panel padding */
  }

  .footer.open {
    width: calc(100% - 300px);
  }

  .input-container.open {
    width: calc(100% - 540px);
    margin-left: 210px;
  }
}

.container {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  /* Ensures it takes up the full width until it hits 1200px */
  padding: 0 15px;
  /* Optional padding to add space inside the container */
}

/* Navbar styling */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 63px;
  background-color: rgba(155, 155, 155, 0.25);
  border-bottom: 1px solid #666;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 201;
  transition: width 0.3s ease;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  width: 100%;
}

/* Adjustments for the navbar content */
.navbar-content {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.navbar-links {
  padding-right: 15px;
}

/* Styling for the hamburger icon */
.navbar-hamburger {
  width: 25px;
  /* Match size to the logo */
  height: auto;
  margin-right: 18px;
  /* Space between the hamburger icon and the logo */
  cursor: pointer;
  /* Makes the hamburger icon clickable */
}

/* Styling for the logo with the box-shadow retained */
.navbar-logo {
  width: 45px;
  /* Ensure the size of the logo remains consistent */
  height: auto;
  margin-right: 18px;
  /* Space between the logo and the text */
  border-radius: 8px;
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.75);
  /* Retained box-shadow */
}

/* Make the title container flexible */
.navbar-title {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

/* Allow "Rabin Forest" to break into two lines */
.navbar-title h1 {
  margin: 0;
  font-size: 20px;
  word-wrap: break-word;
  white-space: normal;
}

/* Subheader styling */
.subheader {
  font-size: 11px;
  margin: 0;
  text-align: left;
}

.toggle-panel-btn, .clear-links-btn button, .chat-buttons button{
  border: none;
  background-color: #D6B4E7;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  float: right;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}
.chat-buttons button{
  background-color: #e9ffd0;
}
.chat-buttons {
margin: 20px 0 0 0;
}
.clear-links-btn {
  border-top: 1px solid #666;
  padding: 15px;
}
.badge {
  background-color: #e53935;
  color: white;
  border-radius: 50%;
  padding: 3px 7px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: -10px;
}

/* Slide-out panel styling */
.slideout-panel {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #333;
  color: white;
  padding: 0;
  transition: right 0.3s ease;
  z-index: 400;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  /* Ensures the header stays at the top */
}
h2 {
  margin: 10px 0;
}

.slideout-panel h2 {
  margin: 0;
  font-size: 20px;
}

.slideout-panel hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #666;
}

/* Fixed header section */
.slideout-header {
  padding: 18px 20px 18px 20px;
  background-color: #333;
  border-bottom: 1px solid #666;
  z-index: 1;
  flex-shrink: 0;
  /* Ensures the header doesn't shrink when the links overflow */
}

/* Scrollable links section */
.slideout-links {
  flex-grow: 1;
  /* Take up remaining space */
  overflow-y: auto;
  /* Scrollable when content overflows */
  padding: 10px 20px;
  background-color: #333;
}

.slideout-panel.open {
  right: 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);

}

@media (min-width: 768px) {
  .slideout-panel.open {
    right: 0;
    box-sizing: none;
    border-left: 1px solid #666
  }
}

.slideout-panel a {
  color: #ffffff;
  text-decoration: none;
  font-size: 17px;
  word-wrap: break-word;
}

.slideout-panel a:hover {
  text-decoration: underline;
}

.footer {
  background-color: rgba(155, 155, 155, 0.25);
  /* Adjusted transparency for the footer */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  border-top: 1px solid #666;
  color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 150;
  transition: width 0.3s ease;
  width: 100%;
}

.footer p {
  margin: 0;
  font-size: 12px;
}

/* Conversation area styling */
.conversation {
  position: relative;
  margin-top: 63px;
  width: 100%;
  overflow-y: auto;
  padding: 15px;
  box-sizing: border-box;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.conversation div {
  margin-bottom: 10px;
  font-size: clamp(1.10rem, 1.10vw + 1rem, 1.70rem);
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.conversation .user-message {
  text-align: right;
  overflow-wrap: break-word;
}

.conversation .assistant-message {
  text-align: left;
  overflow-wrap: break-word;
}

.message-bubble {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  max-width: 85%;
  position: relative;
  /* Needed for the triangle */
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.user-message .message-bubble {
  background-color: #A3C4F3;
  color: black;
}

.assistant-message .message-bubble {
  background-color: #797878;
  color: white;
}

.assistant-message .message-bubble a {
  color: #ffffff;
}

.assistant-message .message-bubble a:hover {
  color: #dddddd;
}

/* Triangle for the user message */
.user-message .message-bubble::after {
  content: "";
  position: absolute;
  bottom: -15px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 1px solid transparent;
  border-top: 15px solid #A3C4F3;
  /* Same as the user bubble background */
}

/* Triangle for the assistant message */
.assistant-message .message-bubble::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 15px;
  width: 0;
  height: 0;
  border-left: 1px solid transparent;
  border-right: 30px solid transparent;
  border-top: 15px solid #797878;
  /* Same as the assistant bubble background */
}

/* Input container styling */
.input-container {
  border-top: 1px solid #333;
  background-color: #1e1e1e !important;
  position: fixed;
  bottom: 30px;
  /* Leaves space for the footer */
  left: 0;
  right: 0;
  z-index: 99;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.input-container textarea {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  margin: 10px 0;
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: #2c2c2c;
  color: #e0e0e0;
  border: 3px solid #555;
  font-size: 20px;
  resize: none;
  height: 65px;
}

.input-container button, .form-button-container button {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  display: block;
  width: 100%;
  padding: 15px;
  /* background-image: linear-gradient(to bottom, #1e88e5 0%, #92c4ef 49%, #064d8c 50%, #1e88e5 100%); */
  background-color: #D6B4E7;
  border: none;
  color: black;
  border-radius: 15px;
  cursor: pointer;
  font-size: 20px;
}

button:hover {
  opacity: 0.85;
}

.submit {
  font-weight: bolder;
}

button:disabled {
  background-color: #555;
}

/* Error message styling */
.error {
  width: 100%;
  background-color: #e57373;
  color: #e0e0e0;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
}

.reset-button {
  margin-top: 15px;
  padding: 8px !important;
  /* background-color: #439713 !important; */
  /* background-image: linear-gradient(to bottom, #439713 0%, #439713 88%, #244212 100%) !important; */
  background-color: #4B4B4B !important;
  border: none;
  font-weight: normal;
  color: white !important
}

/* Overlay mask with blur transition */
.overlay-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  /* Initially transparent */
  z-index: 150;
  pointer-events: none;
  /* Prevent interactions when hidden */
  transition: opacity 0.5s ease;
  opacity: 0;
}

.overlay-mask.show {
  opacity: 1;
  /* Make the mask visible */
  pointer-events: all;
  /* Allow interactions when shown */
}

.overlay-content {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0);
  /* Initially no blur */
  -webkit-backdrop-filter: blur(0);
  /* Safari-specific blur */
  transition: backdrop-filter 0.5s ease, -webkit-backdrop-filter 0.5s ease;
  /* Smooth blur transition */
}

.overlay-mask.show .overlay-content {
  backdrop-filter: blur(0.75px);
  /* Apply blur */
  -webkit-backdrop-filter: blur(.075px);
  /* Safari-specific blur */
  background-color: rgba(0, 0, 0, 0.2);
  /* Optional background tint */
}

/* Only show the overlay on mobile screens */
@media (min-width: 768px) {
  .overlay-mask {
    display: none;
  }
}

/* Only show the overlay on mobile screens */
@media (min-width: 768px) {
  .overlay-mask {
    display: none;
  }
}

/* Left Slide-out Menu */
.menu-panel {
  position: fixed;
  top: 0;
  left: -210px;
  width: 210px;
  height: 100%;
  background-color: #333;
  color: white;
  transition: left 0.3s ease;
  z-index: 200;
  font-size: 16px;
  margin-top: 64px;
}

.menu-panel.open {
  left: 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.menu-header {
  padding: 20px 20px 10px 20px;
  background-color: #444;
  border-bottom: 1px solid #666;
  display: flex;
}

.menu-header h2 {
  margin: 0;
  display: inline-block;
}

.menu-content {
  padding: 0
}

.menu-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-content li {
  padding: 0;
  border-bottom: 1px solid #000;
}

.menu-content li:first-child {
  border-top: 1px solid #000;

}

.menu-content li.active {
  background-color: #222;
}

.menu-content a {
  display: block;
  padding: 10px;
  color: white;
  text-decoration: none;
}
.menu-content a:hover {
  background-color: #555;
}

.playground-menu-header {
  display: block;
  padding: 30px 10px 10px 10px;
  color: white;
  text-decoration: none;
}

/* Adjust the App content when menu is open */
.App.menu-open {
  margin-left: 210px;
}

/* Ensure both panels work properly */
@media (min-width: 768px) {
  .App.menu-open {
    width: calc(100% - 210px);
  }
}

/* Container Styling */
.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow-y: auto;
}
.form-container.generating {
  /* opacity: 0.50; */
  pointer-events: none;
}

/* Form Styling */
.dalle-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: scroll;
  width: 100%;
}

.dalle {
  margin-bottom: 20px;
}

.dalle-3-image {
  margin-top: 15px;
  width: 100%;
  border: 1px solid #fff;
}

.form-left {
  flex: 1 1 48%; /* Take up half the space in desktop view */
  display: flex;
  flex-direction: column;
}

.form-right {
  flex: 1 1 48%; /* Take up half the space in desktop view */
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 8px;
  font-weight: bold;
}

.form-textarea {
  outline: none;
  width: calc(100% - 0px);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #2c2c2c;
  color: #e0e0e0;
  border: 1px solid #ccc;
  font-size: 20px;
  resize: none;
  height: 100%;
  outline: none;
}

.form-select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

select {
  background-color: #2c2c2c;
  color: #e0e0e0;
  border: 3px solid #555;
  font-size: 20px;
}

/* Submit Button */
.form-button-container {
  width: 100%;
  text-align: center;
}

/* Responsive Styling */
@media (max-width: 767px) {
  .dalle-form {
    /* flex-direction: column;  */
  }

  .form-left, .form-right {
    flex: 1 1 100%; /* Take full width on mobile */
  }
}

.response {
  width: 100%;
}

.response-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #fff;
  position: relative;
}

.playground-content {
  overflow-y: auto;
}

.playground-content h2 {
  /* margin-top: 0; */
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.navbar-help-icon, .playground-help-icon {
  font-size: 1.5rem;
  margin: 0 0 25px 10px;
  color: var(--highlight-color); /* Replace with your app's highlight color */
  cursor: pointer;
}

.playground-help-icon {
  margin: 15px 0 15px 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  margin: 0 20px;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal h2 {
  margin-top: 0;
}

.modal p {
  margin: 15px 0;
  font-size: 1rem;
  line-height: 1.5;
}

.modal-close-btn {
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-close-btn:hover {
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top: 2px solid black; /* Spinner color */
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-button-container .form-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-chat-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.ai-chat-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ai-chat-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ai-chat-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.ai-chat-messages {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message-bubble {
  max-width: 80%;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.message-bubble.assistant-a {
  align-self: flex-start;
  background-color: #d0e6ff;
  color: #003366;
}

.message-bubble.assistant-b {
  align-self: flex-end;
  background-color: #e9ffd0;
  color: #336600;
}

.message-text {
  margin: 0;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* min-height: calc(100vh - 380px);
  max-height: calc(100vh - 380px); */
  border-top: 1px solid #ddd;
  scroll-behavior: smooth;
}

/* AI Chat - Adjusted to match conversation bubbles */
.message-bubble {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  max-width: 85%;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 25px;
  margin-top: 5px;
}

.assistant-a {
  text-align: left;
  background-color: #d0e6ff; /* Matches assistant-message */
  color: white;
  position: relative;
}

.assistant-a::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 15px;
  width: 0;
  height: 0;
  border-left: 1px solid transparent;
  border-right: 30px solid transparent;
  border-top: 15px solid #d0e6ff;
}

.assistant-b {
  text-align: right;
  background-color: #e9ffd0; /* Matches user-message */
  color: black;
  position: relative;
}

.assistant-b::after {
  content: "";
  position: absolute;
  bottom: -15px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 1px solid transparent;
  border-top: 15px solid #e9ffd0;
}

.message-text {
  margin: 0;
}

.chat-input-area {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-top: 1px solid #ddd;
}

.subject-input {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  margin: 20px 0;
  box-sizing: border-box;
  background-color: #2c2c2c;
  font-size: 20px;
  resize: none;
  height: 70px;
  outline: none;
  width: calc(100% - 0px);
  padding: 10px;
  border-radius: 8px;
  color: #e0e0e0;
  border: 1px solid #ccc;
}

.chat-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.chat-buttons input {
  border-radius: 4px;
  padding:3px;
  border: 1px solid #ccc;
  font-size: 18px;
  flex: 5; /* 3 parts for input */
}

.chat-buttons button {
  flex: 1; /* 1 part for button */
}
button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.header-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.about-button {
  margin-left: 10px;
}
.modal-overlay {
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex; align-items: center; justify-content: center;
}
.modal-content {
  color: black;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  text-align: center;
  margin: 0 20px;
}
.close-button {
  margin-top: 10px;
}

/* src/components/Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top */
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  animation: fadeIn 0.3s ease-in-out;
}

.modal-header {
  text-align: right;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; */
}
.modal-body h2{
  margin-top: 0;
}

.modal-title {
  margin: 0;
  font-size: 1.5rem;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #000000;
}

.modal-body {
  font-size: 1rem;
  padding-bottom: 20px;
}

/* Optional: Add fade-in animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.reset-chat {
  background-color: #4B4B4B !important;
  border: none;
  font-weight: normal;
  color: white !important
}

.gradient-text {
  background: linear-gradient(to right, 
  #708698, 
  #dcdae2, 
  #f4dfe2, 
  #e3bfce, 
  #9f7595);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.overlay-text {
  position: absolute;
  bottom: 20px; /* Centers vertically */
  left: 5%; /* Centers horizontally */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for readability */
  color: #ffffff; /* Text color */
  padding: 10px 20px; /* Padding around the text */
  border-radius: 7px; /* Rounded corners */
  max-width: 80%; /* Ensures the text doesn't overflow the image */
  min-width: 80%;
  word-wrap: break-word; /* Allows long words to break and wrap */
  font-size: 11px;
  text-align: left; 
  max-height: 20%;
  overflow-y: scroll;
}